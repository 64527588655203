.wrapper {
  color: #000;
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

h1 {
  margin: 0 0 12px;
  text-transform: capitalize;
  font-weight: 600;
  font-size: 21px;
}

h3 {
    margin: 0 0 15px;
    color: #B39164;
    text-transform: uppercase;
    font-weight: 600;
}

h2 {
  margin: 0 0 15px;
  text-transform: uppercase;
  font-weight: 600;
  
}

h5 {
  font-weight: 600;
  text-transform: capitalize;
  font-size: 20px;
  margin-top: 0;
  margin-bottom: 6px;
}



a {
  color: #000;
  text-decoration: none;
}


.container {
  padding: 0 15px;
  margin: 0 auto;
  max-width: 1700px;
}


.list-icons {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.list-icons li {
  text-align: center;
  cursor: pointer;
  margin-right: 10px;
  max-width: 100px;
  text-transform: capitalize;
  color: #000;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
} 



.list-icons span{
  display: block;
} 

.list-icons .list-icons--icon{
  border-radius: 50%;
  width: 80px;
  margin: 0 0 13px;
  height: 80px;
  border: 1px solid transparent;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
} 

.list-icons--subtitle {
  font-size: 14px;
  line-height: 1.7;
}


.list-icons--icon  img{
    max-width: 25px;
} 


.list-icons.bg-icons .list-icons--icon {
  background-color: #ebe3d8;
  border-color: #b39164;
}

.list-icons li:last-child{
  margin-right: 0px;
} 


.footer__navlist {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  justify-content: center;
}

.footer__navlist  li {
  text-align: center;
  margin-right: 25px;
}

.footer__navlist  li:last-child{
  margin-right: 0px;
}


@media (max-width: 374px) { 
  .list-icons .list-icons--icon{
    width: 89px;
    height: 89px;
  }
}
