@font-face {
  font-family: TitilliumWeb;
  src: url("assets/fonts/TitilliumWeb-Black.ttf");
  font-weight: 900;
}
@font-face {
  font-family: TitilliumWeb;
  src: url("assets/fonts/TitilliumWeb-Bold.ttf");
  font-weight: 700;
}
@font-face {
  font-family: TitilliumWeb;
  src: url("assets/fonts/TitilliumWeb-BoldItalic.ttf");
  font-weight: 700;
}
@font-face {
  font-family: TitilliumWeb;
  src: url("assets/fonts/TitilliumWeb-ExtraLight.ttf");
  font-weight: 200;
}

@font-face {
  font-family: TitilliumWeb;
  src: url("assets/fonts/TitilliumWeb-Italic.ttf");
  font-style: italic;
  font-weight: 400;
}
@font-face {
  font-family: TitilliumWeb;
  src: url("assets/fonts/TitilliumWeb-Light.ttf");
  font-weight: 300;
}
@font-face {
  font-family: TitilliumWeb;
  src: url("assets/fonts/TitilliumWeb-LightItalic.ttf");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: TitilliumWeb;
  src: url("assets/fonts/TitilliumWeb-Regular.ttf");
  font-weight: 400;
}
@font-face {
  font-family: TitilliumWeb;
  src: url("assets/fonts/TitilliumWeb-SemiBold.ttf");
  font-weight: 600;
}
@font-face {
  font-family: TitilliumWeb;
  src: url("assets/fonts/TitilliumWeb-SemiBold.ttf");
  font-weight: 600;
}

@font-face {
  font-family: OFL;
  src: url("assets/fonts/OFL.txt");
  font-weight: 400;
}

body {
  overscroll-behavior: contain;
  margin: 0;
  font-family: TitilliumWeb ,-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    font-style: normal;
  background-color: #FAFAFA;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


img {
  width: 100%;
  display: block;
  object-fit: cover;
}